import React, { Suspense, useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Loader,
  OrbitControls,
  CameraShake,
  PerspectiveCamera,
} from "@react-three/drei";
import { Particles } from "./Particles";

// Reusable Text Component for Consistency
const TextBlock = ({ children, className }) => (
  <p className={`text-left font-supply-mono font-normal text-base ${className}`}>
    {children}
  </p>
);

// Highlighted Text Component using Tailwind's Gradient Utilities
const Highlight = ({ children }) => (
  <span className="bg-gradient-to-r from-blue-100 via-zinc-200 to-red-100 bg-clip-text text-transparent">
    {children}
  </span>
);

export default function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640); // Adjust this breakpoint as desired
    };

    // Initial check
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* Background */}
      <div className="fixed w-full h-full bg-[radial-gradient(at_top_right,_#18181b_0%,_#09090b_80%,_#0f0f10_100%)] z-[-10]" />

      {/* First Page */}
      <section className="relative w-full h-[110vh]">
        <h1
          className="absolute top-[10%] left-[5%] m-0 text-left font-supply-mono text-zinc-50 leading-tight 
                        text-[2.5em] sm:text-[4em] md:text-[5em] tracking-[-1px] sm:tracking-[-2px]"
        >
          Autonomous
          <br />
          <span>Cyber</span>
        </h1>
        <Canvas
          className="absolute top-0 left-0 w-full h-screen"
          linear
          shadows
          style={isSmallScreen ? { touchAction: 'pan-y', pointerEvents: 'none' } : {}}
        >
          <PerspectiveCamera
            makeDefault
            fov={isSmallScreen ? 40 : 27}
            position={[0, 0, 5]}
          />
          <OrbitControls
            makeDefault
            autoRotate
            autoRotateSpeed={0.5}
            zoomSpeed={0.1}
            enableZoom={false}
            enabled={!isSmallScreen} // Disable controls on small screens
          />
          <CameraShake
            yawFrequency={1}
            maxYaw={0.05}
            pitchFrequency={1}
            maxPitch={0.05}
            rollFrequency={0.5}
            maxRoll={0.5}
            intensity={0.2}
          />

          {/* Wrap potentially loading elements in Suspense */}
          <Suspense fallback={null}>
            <Particles position={[0.55, 0.2, 0]} />
          </Suspense>
        </Canvas>

        {/* The drei Loader will appear while 3D assets are loading */}
        <Loader />

        <div className="absolute sm:top-[80%] top-[75%] left-[5%] m-0 text-left text-zinc-50 font-supply-mono font-normal text-sm">
          <TextBlock>
            Building the AI platform for offensive cybersecurity.
          </TextBlock>
        </div>
      </section>

      {/* Second Page */}
      <section className="w-full min-h-[300px] flex items-center justify-center px-4 pb-16">
        {/* Quote Panel Container */}
          <div className="absolute left-[5%] w-3/4 text-white font-supply-mono text-lg space-y-6">
            <TextBlock>
              We work strictly in the{" "}
              <Highlight>American</Highlight> national interest and support both
              government and commercial customers.
            </TextBlock>
            <TextBlock>
              Our first product,{" "}
              <a
                href="https://fuzz-e.io"
                className="underline text-white hover:text-gray-300 transition-colors duration-300"
                aria-label="FUZZ-E website"
              >
                FUZZ-E
              </a>
              , has applications
              across vulnerability research, reverse engineering, and penetration
              testing.{" "}
              <a
                href="mailto:support@autonomouscyberco.com"
                className="underline text-white hover:text-gray-300 transition-colors duration-300"
                aria-label="Contact us via email"
              >
                Contact us
              </a>{" "}
              for access.
            </TextBlock>
            <TextBlock>
              We're always looking for first-rate hires. If you're interested in
              joining, drop us a note at{" "}
              <a
                href="mailto:jobs@autonomouscyberco.com"
                className="underline text-white hover:text-gray-300 transition-colors duration-300"
                aria-label="Jobs email"
              >
                <Highlight>jobs[at]autonomouscyberco.com</Highlight>
              </a>
              .
            </TextBlock>
          </div>
      </section>
    </>
  );
}
